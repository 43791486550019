import type {
  MaterialStorage,
  StorageWithMaterial,
  AssetResponse,
  AssetsTransitions,
  AssetLink
} from '@hconnect/common/types'

import type {
  AssetsStandardOperationTimes,
  DefaultAssetsOperationTimes,
  AssetStandardOperationTimeValidationData
} from '../modules/assets/types'
import type {Schedule} from '../modules/common/types'
import {StructureNode} from '../modules/common/types'
import {Constraints} from '../modules/common/types/contstraints'
import {Latest} from '../modules/common/types/latest'
import {
  FunctionsResponse,
  KpiResponse,
  LastResultsResponse,
  SensorsResponse,
  UnitsResponse
} from '../modules/kpi-calculation/types'
import type {
  MaterialWithRecipes,
  MaterialsProducts,
  Recipe,
  Material,
  ProductsForecastInfo,
  GlobalMaterial,
  LimsMaterial
} from '../modules/materials/types'
import {SignalType} from '../modules/upm/components/SignalForm/api/useGetSignalTypes'

import {
  DailyKpiInfo,
  Events,
  Comments,
  CalendarInfo,
  CumulatedKpisResponse,
  CategoriesTreeResponse,
  ResourceDetailsResponse
} from '@settings/modules/janus/types'
import {GlobalMeasurementType} from '@settings/modules/measurements/types/GlobalMeasurementType'
import {GlobalMeasurementTypesCategory} from '@settings/modules/measurements/types/GlobalMeasurementTypesCategory'
import {LimsMeasurementType} from '@settings/modules/measurements/types/LimsMeasurementType'
import {MeasurementTypesMapping} from '@settings/modules/measurements/types/MeasurementTypesMapping'
import {
  LimsSamplingPoint,
  SamplingPoint,
  SamplingPointType
} from '@settings/modules/sampling-points/types'
import {Equipment} from '@settings/modules/upm/types/equipment'
import {SignalDetail, Unit} from '@settings/modules/upm/types/signal'

export const MOCK_REQUEST_TIMEOUT_KEY = 'mockRequestTimeout'

export type Plant = 'burglengenfeld'

export interface PlantMockState {
  schedule: Schedule
  assetsStandardOperationTimes: AssetsStandardOperationTimes
  defaultAssetsOperationTimes: DefaultAssetsOperationTimes
  materialsWithRecipes: MaterialWithRecipes[]
  recipesById: Record<string, Recipe>
  materialsById: Record<string, Material>
  materialsProducts: MaterialsProducts
  latest: Latest
  constraints: Constraints
  assets: AssetResponse[]
  assetsTransitions: AssetsTransitions
  assetsLinks: AssetLink[]
  materialStorage: MaterialStorage[]
  storagesWithMaterial: StorageWithMaterial[]
  productsSalesForecastInfo: ProductsForecastInfo
  validatedPostSTDRequests: {[key: string]: boolean}
  validatedPutSTDRequests: {[key: string]: boolean}
  standardTimesValidationErrorResponse: AssetStandardOperationTimeValidationData
  kpis: KpiResponse
  kpiDailyEntries: DailyKpiInfo[]
  kpiCumulatedDailyEntries: CumulatedKpisResponse
  kpiDailyEvents: Events
  kpiDailyComments: Comments
  monthlyEntriesCategories: CategoriesTreeResponse
  resourceDetails: ResourceDetailsResponse
  kpiWeeklyCalendar: CalendarInfo
  kpiMonthlyCalendar: CalendarInfo
  sensors: SensorsResponse
  functions: FunctionsResponse
  kpiUnits: UnitsResponse
  globalMeasurementTypesCategories: GlobalMeasurementTypesCategory[]
  globalMeasurementTypes: GlobalMeasurementType[]
  limsMeasurementTypes: LimsMeasurementType[]
  measurementTypesMappings: MeasurementTypesMapping[]
  samplingPointsTypes: SamplingPointType[]
  limsSamplingPoints: LimsSamplingPoint[]
  samplingPoints: SamplingPoint[]
  lastResults: LastResultsResponse
  upm: {
    structure: StructureNode
    equipment: Equipment[]
    sensors: SignalDetail[]
    units: Unit[]
    signal: SignalDetail
    signalType: SignalType[]
  }
  globalMaterials: GlobalMaterial[]
  limsMaterials: LimsMaterial[]
}

export type MockState = Record<Plant, PlantMockState>
